import React, { useEffect } from "react";
import Header from "../Userlayout/Header";
import Footer from "../Userlayout/Footer";
import { connect } from "react-redux";
const Academy = (props) => {
  const profileDetails = props?.profileDetails?.data;

  useEffect(() => {
    // Set up the academy options
    const script = document.createElement("script");
    setTimeout(() => {
      window.academyoptions = {
        host: "https://caterize.launchlms.com",
        token: "bZqfl7q6ssvea3Q2aUdlQVUwOTE=",
        name: profileDetails?.name || "Guest",
        email: profileDetails?.email || "guest@example.com",
        element: "caterizeacademygolive",
        target: "caterizeacademydiv",
        logout: "logoutButtonId", // Replace with actual logout button ID if needed
        timezone: "America/New_York",
        // timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, // Automatically detect user timezone
        group: profileDetails?.groupIds || ["1"], // Pass group IDs dynamically
      };
      // Dynamically create and insert the script

      script.src = "https://caterize.launchlms.com/pages/js/widget2.js";
      script.async = true;

      document.body.appendChild(script);
      const button = document.getElementById(window.academyoptions.element);
      if (button) {
        button.click();
      }
    }, 200);
    return () => {
      // Cleanup script on component unmount
      document.body.removeChild(script);
    };
  }, [profileDetails]);

  return (
    <>
      <Header />
      <main className="userMain">
        <div
          className="content-block"
          style={{ width: "100%", height: "100%" ,padding:"0 !important"}}
        >
          <div
            id="caterizeacademydiv"
            style={{ width: "100%", height: "100vh" }}
          ></div>
          <button id="caterizeacademygolive" style={{ display: "none" }}>
            Go live
          </button>
        </div>
      </main>
      <Footer />
    </>
  );
};
const mapStateToProp = (state) => ({
  // submit: state.getAgentsReducer,
  profileDetails: state.getSuperAdminProfileReducer.profileDetails,
});

const mapDispatchToProps = () => ({});
export default connect(mapStateToProp, mapDispatchToProps)(Academy);
