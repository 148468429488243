
import React from "react";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import { MENU_ITEMS_ADMIN } from "../../constants";
function AdminSidebar() {
  const getPath = useLocation();
  return ( <aside>
    <ul>
      {Object.values(MENU_ITEMS_ADMIN).map((item) => (
        <li key={item.path}>
          <Link
            to={item.path}
            className={classNames("text-decoration-none", {
              active: getPath.pathname === item.path,
            })}
          >
            <i className={item.icon}></i>
            {item.label}
          </Link>
        </li>
      ))}
    </ul>
  </aside>)
}

export default AdminSidebar;
