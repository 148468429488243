import React, { useEffect } from "react";
import AdminHeader from "../../../Components/Header/AdminHeader";
import Footer from "../../../superAdmin/Components/Userlayout/Footer";
import { connect } from "react-redux";

const AdminAcademy = (props) => {
  const profileDetails = props?.profileDetails?.data;

  useEffect(() => {
    const script = document.createElement("script");
    setTimeout(() => {
      // Set up the academy options
      window.academyoptions = {
        host: "https://caterize.launchlms.com",
        token: "bZqfl7q6ssvea3Q2aUdlQVUwOTE=",
        name: profileDetails?.name || "Guest",
        email: profileDetails?.email || "guest@example.com",
        element: "caterizeacademygolive",
        target: "caterizeacademydiv",
        logout: "logoutButtonId", // Replace with actual logout button ID if needed
        timezone: "America/New_York",
        // timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, // Automatically detect user timezone
        group: profileDetails?.groupIds || ["1"], // Pass group IDs dynamically
      };
      // Dynamically create and insert the script

      script.src = "https://caterize.launchlms.com/pages/js/widget2.js";
      script.async = true;

      document.body.appendChild(script);
      const button = document.getElementById(window.academyoptions.element);
      if (button) {
        button.click();
      }
    }, 200);

    return () => {
      // Cleanup script on component unmount
      document.body.removeChild(script);
    };
  }, [profileDetails]);

  return (
    <>
      <div className="content-block" style={{ width: "100%", height: "100%" }}>
        <div
          id="caterizeacademydiv"
          style={{ width: "100%", height: "100vh" }}
        ></div>
        <button id="caterizeacademygolive" style={{ display: "none" }}>
          Go live
        </button>
      </div>
    </>
  );
};

const mapStateToProp = (state) => ({
  // submit: state.getAgentsReducer,
  profileDetails: state.AuthReducer.profileDetails,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProp, mapDispatchToProps)(AdminAcademy);
