export const MENU_ITEMS_ADMIN = {
  DASHBOARD: {
    path: "/admin/dashboard",
    icon: "ri-dashboard-line",
    label: "Dashboard",
  },
  ORDERS: {
    path: "/admin/order",
    icon: "ri-shopping-basket-line",
    label: "Orders",
  },
  DRIVERS: {
    path: "/admin/delivery_agent",
    icon: "ri-user-3-line",
    label: "Drivers",
  },
  MENU_ITEMS: {
    path: "/admin/menus",
    icon: "ri-ancient-gate-line",
    label: "Menu Items",
  },
  DATA_ANALYTICS: {
    path: "/admin/reports",
    icon: "ri-file-chart-line",
    label: "Data Analytics",
  },
  CUSTOMER_INSIGHTS: {
    path: "/admin/customer",
    icon: "ri-team-line",
    label: "Customer Insights",
  },
  // LOCATION_MANAGEMENT: {
  //   path: "/admin/location",
  //   icon: "ri-map-pin-add-line",
  //   label: "Location Management",
  // },
  USER_INFO: {
    path: "/admin/prm",
    icon: "ri-service-line",
    label: "User Info",
  },
  ACADEMY: {
    path: "/admin/academy",
    icon: "ri-book-line",
    label: "Academy",
  },
};
export const MENU_ITEMS_SUPER_ADMIN = {
  DASHBOARD: {
    path: "/superadmin/dashboard",
    icon: "ri-dashboard-line",
    label: "Dashboard",
  },
  ORDERS: {
    path: "/superadmin/order",
    icon: "ri-shopping-basket-line",
    label: "Orders",
  },
  DRIVERS: {
    path: "/superadmin/deliveryAgent",
    icon: "ri-user-3-line",
    label: "Drivers",
  },
  MENU_ITEMS: {
    path: "/superadmin/menu",
    icon: "ri-ancient-gate-line",
    label: "Menu Items",
  },
  DATA_ANALYTICS: {
    path: "/superadmin/reports",
    icon: "ri-file-chart-line",
    label: "Data Analytics",
  },
  CUSTOMER_INSIGHTS: {
    path: "/superadmin/customer",
    icon: "ri-team-line",
    label: "Customer Insights",
  },
  LOCATION_MANAGEMENT: {
    path: "/superadmin/location",
    icon: "ri-map-pin-add-line",
    label: "Location Management",
  },
  USER_INFO: {
    path: "/superadmin/prm",
    icon: "ri-service-line",
    label: "User Info",
  },
  ACADEMY: {
    path: "/superadmin/academy",
    icon: "ri-book-line",
    label: "Academy",
  },
};
