import { Pagination, TablePagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../../../Components/Spinner/Spinner";
import Toaster from "../../../Components/Toaster/Toaster";
import {
  RESET_ADD_AGENT,
  RESET_DELETE_AGENT,
  RESET_UPDATE_AGENT,
} from "../../Constants/Constants";
import {
  deleteAgent,
  getDeliveryAgent,
} from "../../Services/Actions/deliveryAgentAction";
import AddAgent from "./AddAgent";
import UpdateAgent from "./UpdateAgent";
import DeletePopUp from "../../../superAdmin/Components/DeletePopup";
import { formatPhoneNumber } from "../../../Store/type/Constant";
import AdminDeliveyDetails from "./AdminDeliveyDetails";
import { MENU_ITEMS_ADMIN } from "../../../constants";

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <div className="form-outline mb-lg-5 mb-5">
      <label className="form-label me-2 mb-0" htmlFor="form1">
        {/* Search : */}
      </label>
      <input
        id="search"
        type="text"
        className="form-control"
        placeholder="Search"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
      />
    </div>
  </>
);

function AdminDeliveryAgent(props) {
  const [isAddAgent, setIsAgent] = useState(false);
  const [isUpdateAgent, setIsUpdateAgent] = useState(false);
  const [isDeleteAgent, setIsDeleteAgent] = useState(false);

  const notifyError = (msg) => toast.error(msg);

  const [deleteAgentId, setDeleteAgentId] = useState(null);
  const [updatesAgentId, setUpdatedAgentId] = useState(null);

  const [loading, setLoading] = useState(true);

  const [agentList, setAgentList] = useState([]);
  const [isDelete, setIsDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [adminselectedAgentID, setadminselectedAgentID] = useState();
  const [EditShow1, setEditShow1] = useState(false);

  const successMsg = (msg) => toast.success(msg);

  const dispatch = useDispatch();

  useEffect(() => {
    props.getDeliveryAgent();
  }, []);

  useEffect(() => {
    setLoading(props.getAgentList.loading);
    if (props.getAgentList.success) {
      const data = props.getAgentList.data;
      setAgentList([...data]);
    }
  }, [props.getAgentList]);

  const handleDeleteAgent = () => {
    props.deleteAgent(deleteAgentId);
  };
  useEffect(() => {
    if (props.deleteAgentReducer.success) {
      setTimeout(() => {
        successMsg("Delivery agent is deleted successfully. ");
      }, 100);
      setTimeout(() => {
        props.resetdeleteAgent();
      }, 100);
      setIsDeleteAgent(false);
      setDeleteAgentId(null);
      props.getDeliveryAgent();
    } else if (props.deleteAgentReducer.error) {
      notifyError(props.deleteAgentReducer.errors);
      props.resetdeleteAgent();
    }
  }, [props.deleteAgentReducer]);

  useEffect(() => {
    if (props.addAgent.success) {
      setTimeout(() => {
        successMsg("Delivery agent is created successfully. ");
      }, 100);
      setTimeout(() => {
        props.resetAddAgent();
      }, 100);
      props.getDeliveryAgent();
    }
    if (props.addAgent.error) {
      notifyError(props.addAgent.errors);
      props.resetAddAgent();
    }
  }, [props.addAgent]);

  useEffect(() => {
    if (props.updateAgent.success) {
      setTimeout(() => {
        successMsg("Delivery agent is updated successfully. ");
      }, 100);
      setTimeout(() => {
        props.resetAddAgent();
      }, 100);
      props.resetUpdateAgent();
      props.getDeliveryAgent();
    }

    if (props.updateAgent.error) {
      notifyError(props.updateAgent.errors);
      props.resetUpdateAgent();
    }
  }, [props.updateAgent]);

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Contact No.",
      selector: (row) => row.number,
      sortable: true,
    },
    {
      name: "Email Address",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      width: "200px",
    },
    {
      name: "Action",
      selector: (row) => row.action,
      width: "150px",
    },
  ];

  const data = agentList?.map((data, i) => {
    return {
      id: data._id,
      name: data?.name ? data?.name : "N/A",
      number: data?.number ? formatPhoneNumber(data?.number) : "N/A",
      email: data?.email ? data?.email : "N/A",
      status:
        data?.status === 1 ? (
          <span className="badge bg-dark" style={{ width: "67px" }}>
            Active
          </span>
        ) : (
          <span className="badge bg-red" style={{ width: "67px" }}>
            InActive
          </span>
        ),
      action: (
        <>
          <button
            className="btn p-0"
            onClick={() => {
              setEditShow1(true);
              setadminselectedAgentID(data?._id);
            }}
          >
            <i className="fa fa-eye fs-4 me-2 pointer" aria-hidden="true"></i>
          </button>
          <button
            className="btn p-0 me-2"
            onClick={() => {
              setIsUpdateAgent(true);
              setUpdatedAgentId(data?._id);
            }}
          >
            {" "}
            <i
              className="fa fa-pencil-square-o pointer fs-4"
              aria-hidden="true"
            ></i>
          </button>
          <button
            className="btn p-0"
            onClick={() => {
              setIsDelete(true);
              setDeleteId(data?._id);
            }}
          >
            <i
              className="fa fa-trash text-danger fs-4 ms-1"
              aria-hidden="true"
            ></i>
          </button>
        </>
      ),
    };
  });

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const filteredItems = data?.filter(
    (item) =>
      (item.name &&
        item.name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.item_name &&
        item.item_name.toLowerCase().includes(filterText.toLowerCase()))
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <React.Fragment>
      <div className="usermain">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <div className="d-flex justify-content-center align-items-center">
            <Link to={"/"} className="text-dark">
              <i className="fa fa-house fs-2 me-3"></i>
            </Link>
            <h3 className="me-3">/</h3>
            <h3 className="fw-semibold">{MENU_ITEMS_ADMIN.DRIVERS.label}</h3>
          </div>
          <div
            className="border rounded pointer px-4 py-2 text-white bg-red"
            onClick={() => setIsAgent(true)}
          >
            Create
          </div>
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <>
            {agentList.length === 0 ? (
              <div className="text-center h5 mt-5 text-red">
                {" "}
                No Agent available
              </div>
            ) : (
              <div className="pay-row">
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  pagination
                  paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                />
              </div>
            )}
          </>
        )}
        <DeletePopUp
          isOpen={isDelete}
          isClose={setIsDelete}
          handleEvent={() => dispatch(deleteAgent(deleteId))}
          confirmMsg="Are you sure about deleting this delivery agent?"
        />
        <Modal
          show={isAddAgent}
          size="lg"
          onHide={() => setIsAgent(false)}
          backdrop="static"
        >
          <AddAgent setIsAgent={setIsAgent} />
        </Modal>
        {/* update */}
        <Modal
          show={isUpdateAgent}
          size="lg"
          backdrop="static"
          onHide={() => setIsUpdateAgent(false)}
        >
          <UpdateAgent
            setIsUpdateAgent={setIsUpdateAgent}
            updatesAgentId={updatesAgentId}
          />
        </Modal>
        {/* delete */}
        <Modal
          show={isDeleteAgent}
          size="lg"
          onHide={() => setIsDeleteAgent(false)}
        >
          <div className="p-4">
            <div className="">
              <div className="text-center my-5">
                <h5>Are you sure you want to delete this agent?</h5>
              </div>
              <Modal.Footer className="mt-3">
                <div
                  className="py-2 px-4 border rounded pointer bg-secondary text-white mx-2"
                  onClick={() => {
                    setIsDeleteAgent(false);
                    setDeleteAgentId(null);
                  }}
                >
                  Cancel
                </div>
                <div
                  className="py-2 px-4 border rounded pointer text-white bg-red mx-2"
                  onClick={handleDeleteAgent}
                >
                  Delete Agent
                </div>
              </Modal.Footer>
            </div>
          </div>
        </Modal>
        {adminselectedAgentID && (
          <Modal
            show={EditShow1}
            onHide={() => setEditShow1(false)}
            size="lg"
            backdrop="static"
            dialogClassName="modal-dialog-scrollable"
          >
            <AdminDeliveyDetails
              adminselectedAgentID={adminselectedAgentID}
              setEditShow1={setEditShow1}
            />
          </Modal>
        )}
      </div>
      <Toaster />
    </React.Fragment>
  );
}

const mapStateToProp = (state) => ({
  getAgentList: state.DeliveryAgentReducer.getAgentList,
  addAgent: state.DeliveryAgentReducer.addAgent,
  updateAgent: state.DeliveryAgentReducer.updateAgent,
  deleteAgentReducer: state.DeliveryAgentReducer.deleteAgent,
});

const mapDispatchToProps = (dispatch) => ({
  getDeliveryAgent: (details) => dispatch(getDeliveryAgent(details)),
  deleteAgent: (id) => dispatch(deleteAgent(id)),
  resetAddAgent: () => dispatch({ type: RESET_ADD_AGENT }),
  resetUpdateAgent: () => dispatch({ type: RESET_UPDATE_AGENT }),
  resetdeleteAgent: () => dispatch({ type: RESET_DELETE_AGENT }),
});

export default connect(mapStateToProp, mapDispatchToProps)(AdminDeliveryAgent);
