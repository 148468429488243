import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import {
  deletemenuitem,
  getMenuItems,
} from "../../Services/Actions/menuAction";
import Spinner from "../../../Components/Spinner/Spinner";
import AddmenuModal from "./AddmenuModal";
import UpdateMenuItems from "./UpdateMenuItems";
import {
  RESET_ADD_MENU,
  RESET_DELETE_MENU,
  RESET_UPDATE_MENU,
  RESET_UPLOAD_MENU_EXCEL,
} from "../../Constants/Constants";
import { Pagination, TablePagination } from "@mui/material";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import DeletePopUp from "../../../superAdmin/Components/DeletePopup";
import { toast } from "react-toastify";
import Toaster from "../../../Components/Toaster/Toaster";
import { AdminUploadCsvMenuItem } from "../../Services/Actions/AuthAction";
import { MENU_ITEMS_ADMIN } from "../../../constants";

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <div className="form-outline order-search">
      <label className="form-label me-2 mb-0" htmlFor="form1">
        {/* Search : */}
      </label>
      <input
        id="search"
        type="text"
        className="form-control"
        placeholder="Search"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
      />
    </div>
  </>
);

function Menus(props) {
  // variable
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [menuItems, setMenuItems] = useState([]);

  const [isAddMenu, setIsAddMenu] = useState(false);
  const [isUpdateItem, setIsUpdateItem] = useState(false);
  const [isDeleteItem, setIsDeleteItem] = useState(false);

  const [updatedItemId, setIsUpdatedId] = useState(null);
  const [deleteItemId, setDeleteItemId] = useState(null);

  const [isDelete, setIsDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [isImportModal, setIsImportModal] = useState(false);
  const [excelFile, setExcelFile] = useState("");

  const successMsg = (msg) => toast.success(msg);
  const notifyError = (msg) => toast.error(msg);

  const [PizzaStatus, setPizzaStatus] = useState("All");

  const [menuList, setmenuList] = useState([]);

  const dispatch = useDispatch();

  // useeffects
  useEffect(() => {
    if (token === null) {
      navigate("/admin/login");
    } else {
    }
  }, []);

  useEffect(() => {
    setLoading(props.getMenuItesmsReducer.loading);
    if (props.getMenuItesmsReducer.success) {
      const data = props.getMenuItesmsReducer.data;
      // const _pagination = props.getMenuItesmsReducer.pagination
      // setPagination({ ..._pagination, page: parseInt(_pagination.page) })
      setMenuItems([...data]);
    }
  }, [props.getMenuItesmsReducer]);

  useEffect(() => {
    if (props.AdminimportMenuItemExcel.success) {
      toast.success("File uploaded successfullt.");
      setIsImportModal(false);
      setExcelFile("");
      props.getMenuItems();
      props.resetFileUpload();
    } else if (props.AdminimportMenuItemExcel.error) {
      toast.error("Upload Error");
    }
  }, [props.AdminimportMenuItemExcel]);

  useEffect(() => {
    if (props.updatedData.success) {
      setTimeout(() => {
        successMsg("A item is updated successfully.");
      }, 100);
      setTimeout(() => {
        props.resetUpdateMenuItems();
      }, 100);
      props.getMenuItems();
    }
  }, [props.updatedData]);

  useEffect(() => {
    if (props.deletedData.success) {
      setTimeout(() => {
        successMsg("A menu item is deleted successfully.");
      }, 100);
      setTimeout(() => {
        props.resetDeleteMenuItems();
      }, 100);
      setIsDeleteItem(false);
      setDeleteItemId(null);
      props.getMenuItems();
    }
  }, [props.deletedData]);

  useEffect(() => {
    if (props.deletedData.error) {
      notifyError(props.deletedData.errors);
      props.resetDeleteMenuItems();
    }
  }, [props.deletedData]);

  useEffect(() => {
    props.getMenuItems();
  }, []);

  useEffect(() => {
    if (!!menuItems) {
      if (PizzaStatus == "All") {
        setmenuList([...menuItems]);
      } else if (PizzaStatus == "Yes") {
        const filterDataPizza = menuItems?.filter((e) => e?.is_pizza == 1);
        setmenuList([...filterDataPizza]);
      } else if (PizzaStatus == "No") {
        const filterDataPizza = menuItems?.filter((e) => e?.is_pizza == 0);
        setmenuList([...filterDataPizza]);
      }
    }
  }, [PizzaStatus, menuItems]);

  useEffect(() => {
    if (props.addMenuData.success) {
      setTimeout(() => {
        successMsg("A new item is added to the list.");
      }, 100);
      setTimeout(() => {
        props.resetAddMenuItems();
      }, 100);
      props.getMenuItems();
    }
  }, [props.addMenuData]);

  // functions

  const handleDeleteItem = () => {
    props.deleteMenuItems(deleteItemId);
  };

  // const handleChangePage = (event, value) => {
  //   props.getMenuItems();
  // };

  const columns = [
    {
      name: "Item Name",
      selector: (row) => row.item_name,
      sortable: true,
    },
    {
      name: "Price",
      selector: (row) => row.price,
      // width: "110px",
    },
    // {
    //   name: "Image",
    //   // selector: (row) => row.image,
    //   sortable: true,
    //   cell: (row) => (
    //     <img
    //       crossOrigin="anonymous"
    //       src={row?.image}
    //       height="70px"
    //       width="90px"
    //       className=" ms-0 m-3"
    //     />
    //   ),
    // },
    {
      name: "Is_Pizza",
      selector: (row) => row.is_pizza,
      // width: "110px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      // width: "110px",
    },

    {
      name: "Action",
      selector: (row) => row.action,
      width: "100px",
    },
  ];

  const data = menuList?.map((data, i) => {
    return {
      id: data._id,
      // location: data?.locationdata[0]?.location_name
      //   ? data?.locationdata[0]?.location_name
      //   : "N/A",
      item_name: data?.item_name ? data?.item_name : "N/A",
      // image: data?.image ? data?.image : "N/A",
      price: data?.price
        ? `${"$" + parseFloat(data?.price)?.toFixed(2)}`
        : "N/A",
      is_pizza:
        data?.is_pizza === 1 ? (
          <span className="badge bg-dark" style={{ width: "60px" }}>
            Yes
          </span>
        ) : (
          <span className="badge bg-red" style={{ width: "60px" }}>
            No
          </span>
        ),
      status: data.locationdata?.status === 1 ? "Active" : "inactive",
      status:
        data?.status === 1 ? (
          <span className="badge bg-dark" style={{ width: "67px" }}>
            Active
          </span>
        ) : (
          <span className="badge bg-red" style={{ width: "67px" }}>
            InActive
          </span>
        ),
      action: (
        <>
          <button
            className="btn p-0 me-2"
            onClick={() => {
              setIsUpdateItem(true);
              setIsUpdatedId(data?._id);
            }}
          >
            {" "}
            <i
              className="fa fa-pencil-square-o pointer fs-4"
              aria-hidden="true"
            ></i>
          </button>
          <button
            className="btn p-0"
            onClick={() => {
              //   onDelete(v._id);
              setIsDelete(true);
              setDeleteId(data?._id);
            }}
          >
            <i
              className="fa fa-trash text-danger fs-4 ms-1"
              aria-hidden="true"
            ></i>
          </button>
        </>
      ),
    };
  });

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const filteredItems = data?.filter(
    (item) =>
      (item.location &&
        item.location.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.item_name &&
        item.item_name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.price &&
        item.price.toLowerCase().includes(filterText.toLowerCase()))
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <React.Fragment>
      <div className="usermain">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <div className="d-flex justify-content-center align-items-center">
            <Link to={"/"} className="text-dark">
              <i className="fa fa-house fs-2 me-3"></i>
            </Link>
            <h3 className="me-3">/</h3>
            <h3 className="fw-semibold">{MENU_ITEMS_ADMIN.MENU_ITEMS.label}</h3>
          </div>
          <div
            className="border rounded pointer px-4 py-2 text-white bg-red"
            onClick={() => setIsAddMenu(true)}
          >
            Create
          </div>
        </div>
        <div className="d-flex">
          <div className="form-group event-drp col-2">
            <Form.Group>
              <Form.Label>Status:</Form.Label>
              <Form.Select
                name="delivery_agent"
                value={PizzaStatus}
                onChange={(e) => setPizzaStatus(e.target.value)}
              >
                <option value={"All"}>All</option>
                <option value={"Yes"}>Pizza</option>
                <option value={"No"}>Others</option>
              </Form.Select>
            </Form.Group>
          </div>
          <div className="form-group event-drp col-2 mt-4 ms-3">
            <button
              className="border rounded pointer px-4 py-2 text-white bg-red"
              onClick={() => {
                // handleExportExcel();
                // setexcelLoading(true);
                setIsImportModal(true);
              }}
            >
              Import Excel
            </button>
          </div>
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <>
            {menuItems.length === 0 ? (
              <div className="text-center h5 mt-5 text-red ">
                {" "}
                No Menu Items available
              </div>
            ) : (
              <div className="my-1">
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  pagination
                  paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                />
              </div>
            )}
          </>
        )}

        <DeletePopUp
          confirmMsg="Are you sure about deleting this item?"
          isOpen={isDelete}
          isClose={setIsDelete}
          handleEvent={() => dispatch(deletemenuitem(deleteId))}
        />

        <Modal
          show={isAddMenu}
          size="lg"
          onHide={() => setIsAddMenu(false)}
          backdrop="static"
        >
          <AddmenuModal setIsAddMenu={setIsAddMenu} />
        </Modal>
        {/* update */}
        <Modal
          show={isUpdateItem}
          size="lg"
          backdrop="static"
          onHide={() => setIsUpdateItem(false)}
        >
          <UpdateMenuItems
            setIsUpdateItem={setIsUpdateItem}
            itemId={updatedItemId}
          />
        </Modal>
        {/* delete */}
        <Modal show={isDeleteItem} size="lg">
          <div className="p-4">
            <div className="">
              <div className="text-center my-5">
                <h5>Are you sure you want to delete this item?</h5>
              </div>
              <Modal.Footer className="mt-3">
                <div
                  className="py-2 px-4 border rounded pointer bg-secondary text-white mx-2"
                  onClick={() => {
                    setIsDeleteItem(false);
                    setDeleteItemId(null);
                  }}
                >
                  Cancel
                </div>
                <div
                  className="py-2 px-4 border rounded pointer text-white bg-red mx-2"
                  onClick={handleDeleteItem}
                >
                  Delete Item
                </div>
              </Modal.Footer>
            </div>
          </div>
        </Modal>
        <Modal
          show={isImportModal}
          onHide={() => {
            setIsImportModal(false);
            setExcelFile("");
          }}
          size="lg"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Upload Csv</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <a
              className="pointer"
              href="/Assets/sample_admin.xlsx"
              download="sample.xlsx"
            >
              <u>
                <h6>Download Sample File</h6>
              </u>
            </a>
            <div className="mt-4">
              {excelFile == "" && (
                <>
                  <input
                    type="file"
                    onChange={(e) => {
                      setExcelFile(e.target.files[0]);
                    }}
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />
                </>
              )}
              {excelFile !== "" && (
                <div className="row mx-0 align-items-center">
                  <div className="col-10 pointer">
                    <u>
                      <h5>{excelFile?.name}</h5>
                    </u>
                  </div>
                  <div className="col-2">
                    <button
                      className="btn p-0"
                      onClick={() => {
                        setExcelFile("");
                      }}
                    >
                      <i
                        className="fa fa-trash text-danger fs-4"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div
              className="border rounded pointer px-4 py-2 text-white bg-red"
              onClick={() => {
                setIsImportModal(false);
                setExcelFile("");
              }}
            >
              Cancel
            </div>
            <div
              className="border rounded pointer px-4 py-2 text-white bg-red"
              onClick={() => {
                if (excelFile !== "") {
                  props.AdminUploadCsvMenuItem({
                    file: excelFile,
                  });
                } else {
                  notifyError("Selcet Excel file");
                }
              }}
            >
              Upload
            </div>
          </Modal.Footer>
          {/* <EditMenu selectedMenuId={selectedMenuId} setEditShow={setEditShow} /> */}
        </Modal>
      </div>
      <Toaster />
    </React.Fragment>
  );
}

const mapStateToProp = (state) => ({
  // loginReducer: state.AuthReducer.loginReducer
  getMenuItesmsReducer: state.MenuItemsReducer.getMenuItems,
  updatedData: state.MenuItemsReducer.updateMenu,
  deletedData: state.MenuItemsReducer.deleteMenu,
  addMenuData: state.MenuItemsReducer.addMenu,
  AdminimportMenuItemExcel: state.AdminimportMenuItemExcel.AdminUploadExcel,
});

const mapDispatchToProps = (dispatch) => ({
  getMenuItems: (details) => dispatch(getMenuItems(details)),
  deleteMenuItems: (id) => dispatch(deletemenuitem(id)),
  AdminUploadCsvMenuItem: (Details) =>
    dispatch(AdminUploadCsvMenuItem(Details)),
  resetUpdateMenuItems: () => dispatch({ type: RESET_UPDATE_MENU }),
  resetAddMenuItems: () => dispatch({ type: RESET_ADD_MENU }),
  resetDeleteMenuItems: () => dispatch({ type: RESET_DELETE_MENU }),
  resetFileUpload: () => dispatch({ type: RESET_UPLOAD_MENU_EXCEL }),
});

export default connect(mapStateToProp, mapDispatchToProps)(Menus);
