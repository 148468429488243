import React from "react";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import { MENU_ITEMS_SUPER_ADMIN } from "../../../constants";

const Sidebar = () => {
  const getPath = useLocation();
  return (
    <>
      <aside>
       <ul>
            {Object.values(MENU_ITEMS_SUPER_ADMIN).map((item) => (
              <li key={item.path}>
                <Link
                  to={item.path}
                  className={classNames("text-decoration-none", {
                    active: getPath.pathname === item.path,
                  })}
                >
                  <i className={item.icon}></i>
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
        {/* <ul>
          <li>
            <Link
              to="/superadmin/dashboard"
              className={classNames("text-decoration-none", {
                active: getPath.pathname === "/superadmin/dashboard",
              })}
            >
              <i className="ri-dashboard-line"></i>Dashboard
            </Link>
          </li>
          <li>
            <Link
              to="/superadmin/order"
              className={classNames("text-decoration-none", {
                active: getPath.pathname === "/superadmin/order",
              })}
            >
              <i className="ri-shopping-basket-line"></i>Orders
            </Link>
          </li>
          <li>
            <Link
              to="/superadmin/deliveryAgent"
              className={classNames("text-decoration-none", {
                active: getPath.pathname === "/superadmin/deliveryAgent",
              })}
            >
              <i className="ri-user-3-line"></i>Drivers
            </Link>
          </li>
          <li>
            <Link
              to="/superadmin/menu"
              className={classNames("text-decoration-none", {
                active: getPath.pathname === "/superadmin/menu",
              })}
            >
              <i className="ri-ancient-gate-line"></i>Menu Items
            </Link>
          </li>
          <li>
            <Link
              to="/superadmin/reports"
              className={classNames("text-decoration-none", {
                active: getPath.pathname === "/superadmin/reports",
              })}
            >
              <i className="ri-file-chart-line"></i>Data Analytics
            </Link>
          </li>
          <li>
            <Link
              to="/superadmin/customer"
              className={classNames("text-decoration-none", {
                active: getPath.pathname === "/superadmin/customer",
              })}
            >
              <i className="ri-team-line"></i>Customer Insights
            </Link>
          </li>
          <li>
            <Link
              to="/superadmin/location"
              className={classNames("text-decoration-none", {
                active: getPath.pathname === "/superadmin/location",
              })}
            >
              <i className="ri-map-pin-add-line"></i>Location Management
            </Link>
          </li>
          <li>
            <Link
              to="/superadmin/prm"
              className={classNames("text-decoration-none", {
                active: getPath.pathname === "/superadmin/prm",
              })}
            >
              <i className="ri-service-line"></i>User Info
            </Link>
          </li>
          <li>
            <Link
              to="/superadmin/academy"
              className={classNames("text-decoration-none", {
                active: getPath.pathname === "/superadmin/academy",
              })}
            >
              <i className="ri-book-line"></i>Academy
            </Link>
          </li>
        </ul> */}
      </aside>
    </>
  );
};

export default Sidebar;
