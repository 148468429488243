import classNames from "classnames";
import { debounce } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import ReactDatePicker from "react-datepicker";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../../../Components/Spinner/Spinner";
import Toaster from "../../../Components/Toaster/Toaster";
import { sortByProperty } from "../../../Helper/helper";
import {
  deleteOrderData,
  exportPdf,
  getAgentsData,
  getDeliveryAgentsDataAction,
  getLocationByOrderDataAction,
  getOrderDataAction,
  getOrderFilterAction,
  getagentsByOrderDataAction,
} from "../../../Store/Action/CreateAgents/UserAction";
import {
  RESET_ADD_SUPER_ADMIN_ORDER,
  RESET_AGENTSWISE_ORDER,
  RESET_DELETE_SUPER_ADMIN_ORDER,
  RESET_EXPORT_PDF,
  RESET_FIND_SUPERADMIN_CUSTOMER_BY_CONTACT,
  RESET_GET_SUPER_ADMIN_LOCATION_BY_OREDR,
  RESET_UPDATE_SUPER_ADMIN_ORDER,
  formatPhoneNumber,
} from "../../../Store/type/Constant";
import DeletePopUp from "../DeletePopup";
import Footer from "../Userlayout/Footer";
import Header from "../Userlayout/Header";
import CreateOrder from "./CreateOrder";
import EditOrder from "./EditOrder";
import OrderDetails from "./OrderDetails";
import { MENU_ITEMS_SUPER_ADMIN } from "../../../constants";

const FilterComponent = ({ filterText, onFilter }) => (
  <>
    <div className="form-outline order-search">
      <label
        className="form-label me-2 mb-0"
        style={{ marginTop: "18px" }}
        htmlFor="form1"
      ></label>
      <input
        id="search"
        type="text"
        className="form-control"
        placeholder="Search"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
        style={{ marginBottom: "30px" }}
      />
    </div>
  </>
);

const Order = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [totalrows, setTotalRows] = useState(100);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading2, setLoading2] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isCreateOrder, setIsCreateOrder] = useState(false);
  const [isDeleteOrder, setIsDeleteOrder] = useState(false);
  const [deleteOrderId, setDeleteOrderId] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [orderStatus, setOrderStatus] = useState("All");
  const [location, setlocation] = useState("");
  const [agent, setagent] = useState("");
  const [orderList, setOrderList] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [SelectedOrderDetailsId, setSelectedOrderDetailsId] = useState();
  const [SelectedEditID, setSelectedEditID] = useState();
  const [EditShow, setEditShow] = useState(false);
  const [OrderEdit, setOrderEdit] = useState(false);
  const [pdfloader, setpdfloader] = useState(false);

  const handleDeleteOrder = () => {
    props.deleteOrder(deleteOrderId);
  };

  const [error, seterror] = useState(false);

  const allLocationData = useSelector(
    (state) => state.getAgentsReducer.user.data
  );
  const LocationByOrder = useSelector(
    (state) => state.getLocationByOrder.getLocationByOrder.data
  );
  const AgentByorder = useSelector(
    (state) => state.getagentsByOrderReducer.getagentsByOrder.data
  );

  const allDeliveryAgents = useSelector(
    (state) => state.getDeliveryAgentsReducer.user.data
  );

  useEffect(() => {
    dispatch(getAgentsData());
  }, []);

  useEffect(() => {
    dispatch(getDeliveryAgentsDataAction({ id: location }));
  }, [location]);

  useEffect(() => {
    const Query = {
      from_date: startDate ? moment(startDate).format("yyyy-MM-DD") : "",
      to_date: endDate ? moment(endDate).format("yyyy-MM-DD") : "",
      location_id: location,
      agent_id: agent,
      isDelivered:
        orderStatus === "All" ? "" : orderStatus === "Ordered" ? "0" : "1",
      currentPage,
      limit,
    };
    if (filterText && filterText?.trim()?.length) {
      Query.filterText = filterText;
    }
    props.getOrderFilterAction(Query);
    setLoading(true);
  }, [endDate, orderStatus, location, agent, currentPage, limit, filterText]);

  useEffect(() => {
    // setLoading(props.orderfilterReducer.loading);
    if (props.orderfilterReducer.success) {
      const data = props.orderfilterReducer?.data;
      setTotalRows(props.orderfilterReducer?.fullData?.totalItems);
      // setTotalRows(props.orderfilterReducer)
      setOrderList(data);
      setLoading(false);
    } else if (props.orderfilterReducer.error) {
      setLoading(false);
    }
  }, [props.orderfilterReducer]);

  // useEffect(() => {
  //   dispatch(getOrderDataAction());
  // }, []);

  useEffect(() => {
    if (props.deleteOrderReducer?.success) {
      setlocation("");
      props.getOrderFilterAction({
        from_date: startDate ? moment(startDate).format("yyyy-MM-DD") : "",
        to_date: endDate ? moment(endDate).format("yyyy-MM-DD") : "",
        location_id: location,
        agent_id: agent,
        isDelivered:
          orderStatus === "All" ? "" : orderStatus === "Ordered" ? "0" : "1",
      });
      toast.success("Order deleted successfully.... ");
      props.resetDeleteOrder();
      setLoading(true);
      setDeleteOrderId(null);
      setIsDeleteOrder(false);
    }
  }, [props.deleteOrderReducer]);

  useEffect(() => {
    if (props.createOrderReducer.success) {
      props.getOrderFilterAction({
        from_date: startDate ? moment(startDate).format("yyyy-MM-DD") : "",
        to_date: endDate ? moment(endDate).format("yyyy-MM-DD") : "",
        location_id: location,
        agent_id: agent,
        isDelivered:
          orderStatus === "All" ? "" : orderStatus === "Ordered" ? "0" : "1",
      });
      setLoading(true);
      props.resetCreateOrder();
      handleClose();
    }
  }, [props.createOrderReducer]);

  useEffect(() => {
    if (endDate !== null && endDate !== "") {
      setLoading2(true);
      setTimeout(() => {
        setLoading2(false);
      }, 200);
    }
  }, [endDate]);

  useEffect(() => {
    if (props.exportpdfReducer?.success) {
      setpdfloader(false);
    }
  }, [props.exportpdfReducer]);

  const columns = [
    {
      name: "Order ID",
      selector: (row) => row.order_name,
      sortable: true,
      // width: "240px",
    },
    {
      name: "Business Name",
      selector: (row) => row.name,
      sortable: true,
      // width: "240px",
    },
    {
      name: "Contact No.",
      selector: (row) => row.number,
      sortable: true,
      // width: "240px",
    },
    {
      name: "Delivery Date / Time",
      selector: (row) => row.deliverytime,
      sortable: true,
      width: "240px",
    },
    {
      name: "Location",
      selector: (row) => row.customerlocation,
      sortable: true,
      // width:"240px"
    },
    {
      name: "Order Status",
      selector: (row) => row.orderstatus,
      sortable: true,
      // width:"240px"
    },
    {
      name: "ezCater / OLO",
      selector: (row) => row.ezCater,
      sortable: true,
      // width:"240px"
    },
    {
      name: "Action",
      selector: (row) => row.action,
      width: "160px",
    },
  ];
  // console.log(orderList);
  const data = orderList?.map((data, i) => {
    return {
      id: data?._id,
      order_name: data?.order_name ? data?.order_name?.toUpperCase() : "N/A",
      name: data?.address?.[0]?.name ? data?.address?.[0]?.name : "N/A",
      orderstatus: data?.isDelivered === 0 ? "Ordered" : "Delivered",
      customerlocation: data?.locationdata?.[0]?.location_name,
      number: data?.customers?.[0]?.number
        ? formatPhoneNumber(data?.customers?.[0]?.number)
        : "N/A",
      location_name: data?.locationdata?.[0]?.location_name,
      deliverytime:
        !!data.deliveryDateUnform &&
        `${moment(data.deliveryDateUnform, "MM-DD-yyyy").format(
          "MMMM Do, YYYY"
        )}${"/"}${data?.deliverytimestring}`,
      // deliverytime: !!data.deliveryDateUnform && `${moment(data.deliveryDateUnform, 'MM-DD-yyyy').format(
      //   "MMMM Do, YYYY"
      // )}${"/"}${moment(data.createdAt).format("h:mm a")}`,
      status:
        data?.status == 1 ? (
          <span className="badge bg-dark">Active</span>
        ) : (
          <span className="badge bg-red">InActive</span>
        ),
      ezCater:
        data?.ezCater == 1 ? (
          <span style={{ width: "60px" }}>ezCater</span>
        ) : data?.ezCater == 2 ? (
          <span style={{ width: "60px" }}>OLO</span>
        ) : (
          <span style={{ width: "60px" }}>N/A</span>
        ),
      action: (
        <>
          {data?.isDelivered === 0 && (
            <button
              className="btn p-0 me-2"
              onClick={() => {
                setOrderEdit(true);
                setSelectedEditID(data?._id);
              }}
            >
              <i
                className="fa fa-pencil-square-o pointer fs-4"
                aria-hidden="true"
              ></i>
            </button>
          )}
          <button
            className="btn p-0"
            onClick={() => {
              props.exportPdf(data?._id);
              setpdfloader(true);
            }}
          >
            <i
              className="fa-regular fa-file-pdf fs-4 me-2 pointer"
              aria-hidden="true"
            ></i>
          </button>
          <button
            className="btn p-0"
            onClick={() => {
              setEditShow(true);
              // console.log(data?._id);
              setSelectedOrderDetailsId(data?._id);
            }}
          >
            <i className="fa fa-eye fs-4 me-2 pointer" aria-hidden="true"></i>
          </button>
          <button
            className="btn p-0"
            onClick={() => {
              setIsDelete(true);
              setDeleteId(data?._id);
            }}
          >
            <i className="fa fa-trash text-danger fs-4" aria-hidden="true"></i>
          </button>
        </>
      ),
    };
  });

  // const filteredItems = data?.filter(
  //   (item) =>
  //     (item.location &&
  //       item.location.toLowerCase().includes(filterText.toLowerCase())) ||
  //     (item.name &&
  //       item.name.toLowerCase().includes(filterText.toLowerCase())) ||
  //     (item.email &&
  //       item.email.toLowerCase().includes(filterText.toLowerCase())) ||
  //     (item.order_name &&
  //       item.order_name.toLowerCase().includes(filterText.toLowerCase()))
  // );

  // console.log('filteredItems', orderList)

  // const subHeaderComponentMemo = React.useMemo(() => {
  //   const handleClear = () => {
  //     if (filterText) {
  //       setResetPaginationToggle(!resetPaginationToggle);
  //       setFilterText("");
  //     }
  //   };

  //   return (
  //     <FilterComponent
  //       onFilter={(e) => setFilterText(e.target.value)}
  //       onClear={handleClear}
  //       filterText={filterText}
  //     />
  //   );
  // }, [filterText, resetPaginationToggle]);

  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    if (
      startDate !== "" &&
      startDate !== null &&
      (endDate === "" || endDate === null) &&
      !isOpen
    ) {
      toast.error("Please select the next date in the picker.");
    }
  }, [isOpen]);

  useEffect(() => {
    if (props.editOrderReducer.success) {
      props.getOrderFilterAction({
        from_date: startDate ? moment(startDate).format("yyyy-MM-DD") : "",
        to_date: endDate ? moment(endDate).format("yyyy-MM-DD") : "",
        location_id: location,
        agent_id: agent,
        isDelivered:
          orderStatus === "All" ? "" : orderStatus === "Ordered" ? "0" : "1",
      });
      props.resetUpdateOrder();
      setLoading(true);
    }
  }, [props.editOrderReducer]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    setCurrentPage(page);
    setLimit(newPerPage);
  };
  const debouncedSetFilterText = useCallback(
    debounce((value) => {
      setFilterText(value);
    }, 300),
    [] // Ensure the debounce function is created only once
  );
  return (
    <>
      <Header />
      <main className="userMain">
        <div className="content-block">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <div className="d-flex justify-content-center align-items-center">
              <Link to={"/"} className="text-dark">
                <i className="fa fa-house fs-2 me-3"></i>
              </Link>
              <h3 className="me-3">/</h3>
              <h3 className="fw-semibold">{MENU_ITEMS_SUPER_ADMIN.ORDERS.label}</h3>
            </div>
            <div
              className="d-flex justify-content-end"
              onClick={() => setIsCreateOrder(true)}
            >
              <button
                className="border rounded pointer text-white bg-red py-2"
                style={{ width: "120px" }}
              >
                Create
              </button>
            </div>
          </div>
          {/* </Link> */}
          <>
            <div className="row gx-2">
              {/* <div className=" col-2 mt-4"> */}
              <Form.Group className="col col-lg-2 col-md-3">
                <Form.Label className="mb-1" style={{ marginBottom: "-20px" }}>
                  From / To Date:
                </Form.Label>
                <ReactDatePicker
                  showYearDropdown
                  onInputClick={() => setIsOpen(true)}
                  onClickOutside={() => setIsOpen(false)}
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText="MM-DD-YYYY"
                  dateFormat={"MM-dd-yyyy"}
                  className={classNames("form-control", {
                    requireField: error,
                  })}
                  onChange={(date) => {
                    if (!!date) {
                      setStartDate(date[0]);
                      setEndDate(date[1]);
                    }
                    if (date[1]) {
                      setIsOpen(false);
                    }
                  }}
                  // isClearable={true}
                />
              </Form.Group>
              {/* </div> */}
              <div className="col col-lg-2 col-md-3">
                <Form.Group>
                  <Form.Label>Status:</Form.Label>
                  <Form.Select
                    name="delivery_agent"
                    value={orderStatus}
                    onChange={(e) => setOrderStatus(e.target.value)}
                  >
                    <option value={"All"}>All</option>
                    <option value={"Ordered"}>Ordered</option>
                    <option value={"Delivered"}>Delivered</option>
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="form-group event-drp col col-lg-2 col-md-3 mb-1">
                <Form.Label>Location:</Form.Label>
                <select
                  id="location"
                  name="location"
                  value={location}
                  onChange={(e) => setlocation(e.target.value)}
                  className="form-select"
                >
                  <option value="" className="d-none">
                    Select Location
                  </option>
                  <option value="" className="">
                    {`${"All Location (Orders)"}`}
                  </option>
                  {sortByProperty(allLocationData,"location_name")?.map((v, i) => {
                    return (
                      <option value={v?._id} key={i}>
                        {v.location_name}
                      </option>
                    );
                  })}
                </select>
              </div>
              
                <div className="form-group event-drp col col-lg-2 col-md-3 mb-1">
                  <Form.Label>Drivers:</Form.Label>
                  <select
                    id="agents"
                    name="agents"
                    value={agent.agent}
                    onChange={(e) => setagent(e.target.value)}
                    className="form-select"
                  >
                    {/* <option value="" className="d-none">
                        Select Agent
                      </option> */}
                    <option value="" className="">
                      {`${"Select Agent"}`}
                    </option>
                    {sortByProperty(allDeliveryAgents,"name")?.map((v, i) => {
                      return (
                        <option value={v?._id} key={i}>
                          {v.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              
                <div
            className="col col-lg-2 col-md-3"
              // className="row gx-2"
              style={{
                // display: "flex",
                // justifyContent: "end",
                // alignItems: "center",
              }}
            >
              <FilterComponent
                onFilter={(e) => {
                  debouncedSetFilterText(e.target.value);
                }}
                onClear={() => {
                  setFilterText("");
                }}
                // filterText={filterText}
              />
            </div>
            </div>
          
            {loading ? (
              <div className="mt-5">
                <Spinner />
              </div>
            ) : (
              <>
                {orderList.length === 0 ? (
                  LocationByOrder ? (
                    <div className="text-center h5 mt-5 text-red">
                      There are no orders at selected location/restaurant
                    </div>
                  ) : orderStatus == "Ordered" || orderStatus == "Delivered" ? (
                    <div className="text-center h5 mt-5 text-red">
                      Sorry! There are no orders under the selected status.
                    </div>
                  ) : AgentByorder?.length === 0 ? (
                    <div className="text-center h5 mt-5 text-red">
                      Sorry! There are no orders under the selected agent.
                    </div>
                  ) : (
                    <div className="text-center h5 mt-5 text-red">
                      There are no orders at the selected date range.
                    </div>
                  )
                ) : loading2 ? (
                  <>
                    <div className="mt-5">
                      <Spinner />
                    </div>
                  </>
                ) : (
                  <>
                    <>
                      {/* <DataTable
                        columns={columns}
                        data={filteredItems}
                        pagination
                        paginationServer
                        // paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        subHeader
                        paginationTotalRows={totalrows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        subHeaderComponent={subHeaderComponentMemo}
                      /> */}
                    </>
                  </>
                )}
              </>
            )}
            <DataTable
              columns={columns}
              data={data}
              pagination
              progressPending={loading}
              paginationServer
              // paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
              subHeader
              paginationTotalRows={totalrows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              // subHeaderComponent={subHeaderComponentMemo}
            />
          </>
        </div>
      </main>
      <Footer />
      <Modal
        show={isCreateOrder}
        size="xl"
        onHide={() => {
          setIsCreateOrder(false);
          props.resetFindCustomer();
        }}
        dialogClassName="modal-dialog-scrollable"
        backdrop="static"
      >
        <CreateOrder setIsCreateOrder={setIsCreateOrder} />
      </Modal>

      <Modal
        show={isDeleteOrder}
        size="lg"
        onHide={() => setIsDeleteOrder(false)}
        backdrop="static"
      >
        <div className="p-4">
          <div className="">
            <div className="text-center my-5">
              <h5>Are you sure you want to delete this order?</h5>
            </div>
            <Modal.Footer className="mt-3">
              <div
                className="py-2 px-4 border rounded pointer bg-secondary text-white mx-2"
                onClick={() => {
                  setIsDeleteOrder(false);
                  setDeleteOrderId(null);
                }}
              >
                Cancel
              </div>
              <div
                className="py-2 px-4 border rounded pointer text-white bg-primary mx-2"
                onClick={handleDeleteOrder}
              >
                Delete Agent
              </div>
            </Modal.Footer>
          </div>
        </div>
      </Modal>
      <DeletePopUp
        isOpen={isDelete}
        isClose={setIsDelete}
        handleEvent={() => dispatch(deleteOrderData(deleteId))}
        confirmMsg="Are you sure about deleting this order?"
      />
      {SelectedOrderDetailsId && (
        <Modal
          show={EditShow}
          onHide={() => setEditShow(false)}
          size="md"
          backdrop="static"
          dialogClassName="modal-dialog-scrollable"
        >
          <OrderDetails
            SelectedOrderDetailsId={SelectedOrderDetailsId}
            setEditShow={setEditShow}
          />
        </Modal>
      )}

      <Modal
        show={OrderEdit}
        onHide={() => setOrderEdit(false)}
        size="xl"
        dialogClassName="modal-dialog-scrollable"
        backdrop="static"
      >
        <EditOrder
          SelectedEditID={SelectedEditID}
          setOrderEdit={setOrderEdit}
        />
      </Modal>

      <Toaster />
      {pdfloader && (
        <div
          className=" text-center mt-3"
          style={{
            position: "absolute",
            top: "0px",
            bottom: "0px",
            right: "0px",
            left: "0px",
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          }}
        >
          <div className="d-flex justify-content-center align-items-center h-100 w-100">
            <div className="mt-3" style={{ opacity: 1 }}>
              <Spinner />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProp = (state) => ({
  SuperAdminOrderReducer: state.SuperAdminOrderReducer,
  GetorderReducer: state.SuperAdminOrderReducer.getOrder,
  createOrderReducer: state.SuperAdminOrderReducer.addOrder,
  deleteOrderReducer: state.SuperAdminOrderReducer.deleteOrder,
  getAgentsReducer: state.getAgentsReducer,
  getLocationByOrder: state.getLocationByOrder.getLocationByOrder,
  getagentsByOrderReducer: state.getagentsByOrderReducer.getagentsByOrder,
  exportpdfReducer: state.exportpdfReducer.exportpdf,
  orderfilterReducer: state.SuperAdminOrderReducer.orderfilterSuperadmin,
  editOrderReducer: state.SuperAdminOrderReducer.updateOrder,
});

const mapDispatchToProps = (dispatch) => ({
  getOrder: (Details) => dispatch(getOrderDataAction(Details)),
  getOrderFilterAction: (Details) => dispatch(getOrderFilterAction(Details)),
  exportPdf: (details) => dispatch(exportPdf(details)),
  getLocationByOrderDataAction: (id) =>
    dispatch(getLocationByOrderDataAction(id)),
  getagentsByOrderDataAction: (id) => dispatch(getagentsByOrderDataAction(id)),
  getDeliveryAgentsDataAction: (Details) =>
    dispatch(getDeliveryAgentsDataAction(Details)),
  resetDeleteOrder: () => dispatch({ type: RESET_DELETE_SUPER_ADMIN_ORDER }),
  resetCreateOrder: () => dispatch({ type: RESET_ADD_SUPER_ADMIN_ORDER }),
  resetFindCustomer: () =>
    dispatch({ type: RESET_FIND_SUPERADMIN_CUSTOMER_BY_CONTACT }),
  resetOrder: () => dispatch({ type: RESET_GET_SUPER_ADMIN_LOCATION_BY_OREDR }),
  resetAgent: () => dispatch({ type: RESET_AGENTSWISE_ORDER }),
  resetExportPdf: () => dispatch({ type: RESET_EXPORT_PDF }),
  resetUpdateOrder: () => dispatch({ type: RESET_UPDATE_SUPER_ADMIN_ORDER }),
});
export default connect(mapStateToProp, mapDispatchToProps)(Order);
